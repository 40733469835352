import { createAction, props } from '@ngrx/store';
import { IUser, IEmailLoginRequest, ILogoutRequest, IEmailSignupRequest, IVerifyEmailRequest, IResendEmailVerificationRequest, 
    IAuthCredentials, IUserProfileAPIRequest, countryInfo, ITimeZoneInfo, IUserPrimaryAddress, IVerifyPhoneNoRequest } from './auth.models'; // Adjust the import path based on your actual file structure
import { IAPIErrorResponse } from '../../../models/base';

// login
export const loginRequest = createAction(
    '[Auth] Login',
    props<{ credentials: IEmailLoginRequest }>()
);
export const loginSuccess = createAction('[Auth] Login Success');
export const loginFailure = createAction(
    '[Auth] Login Failure',
    props<{ error: IAPIErrorResponse }>()
);
export const setAuthCredentials = createAction('[Auth] set Auth Credentials', props<{ credentials: IAuthCredentials }>());

// signUp
export const signupRequest = createAction('[Auth] SignUp', props<{ credentials: IEmailSignupRequest }>());
export const signupSuccess = createAction('[Auth] SignUp Success');
export const signupFailure = createAction('[Auth] SignUp Failure', props<{ error: IAPIErrorResponse }>());

// verify email
export const verifyEmailRequest = createAction('[Auth] VerifyEmail', props<{ credentials: IVerifyEmailRequest }>());
export const verifyEmailSuccess = createAction('[Auth] VerifyEmail Success');
export const verifyEmailFailure = createAction('[Auth] VerifyEmail Failure', props<{ error: IAPIErrorResponse }>());

// resend verify email
export const resendVerifyEmailRequest = createAction('[Auth] Resend VerifyEmail', props<{ credentials: IResendEmailVerificationRequest }>());
export const resendVerifyEmailSuccess = createAction('[Auth] Resend VerifyEmail Success');
export const resendVerifyEmailFailure = createAction('[Auth] Resend VerifyEmail Failure', props<{ error: IAPIErrorResponse }>());

// Logout
export const logout = createAction('[Auth] Logout', props<{ credentials: ILogoutRequest }>());
export const logoutSuccess = createAction('[Auth] Logout Success');

// Auth User: me
export const getAuthUserRequest = createAction('[Auth] Auth User Request');
export const getAuthUserSuccess = createAction(
    '[Auth] Auth User Success',
    props<{ user: IUser, userPrimaryAddress: IUserPrimaryAddress }>()
);
export const getAuthUserFailure = createAction('[Auth] Auth User Failure');

// Refresh token
export const refreshTokenRequest = createAction('[Auth] Refresh Token Request');
export const refreshTokenSuccess = createAction('[Auth] Refresh Token Success');
export const refreshTokenFailure = createAction('[Auth] Refresh Token Failure', props<{ error: IAPIErrorResponse }>());

// actions for reading tokens from local storage
export const loadTokenFromStorage = createAction('[Auth] Load Token from localstorage');
export const loadTokenSuccess = createAction(
    '[Auth] Load Token Success',
    props<{ accessToken: string, refreshKey: string }>()
);
export const loadTokenFailure = createAction('[Auth] Load Token Failure');

export const updateProfileRequest = createAction('[Auth] Profile Update Request', props<{ credentials: IUserProfileAPIRequest }>());
export const updateProfileSuccess = createAction('[Auth] Profile Update Success');
export const updateProfileFailure = createAction('[Auth] Profile Update Failed', props<{ error: IAPIErrorResponse }>());

// verify email
export const verifyPhoneNoRequest = createAction('[Auth] VerifyPhoneNo', props<{ credentials: IVerifyPhoneNoRequest }>());
export const verifyPhoneNoSuccess = createAction('[Auth] VerifyPhoneNo Success');
export const verifyPhoneNoFailure = createAction('[Auth] VerifyPhoneNo Failure', props<{ error: IAPIErrorResponse }>());

export const setMobileVerificationId = createAction('[Auth] set setMobileVerificationId', props<{ verificationId: string }>());

// verify email
export const resendOTPRequest = createAction('[Auth] resendOTP');
export const resendOTPSuccess = createAction('[Auth] resendOTP Success', props<{ verificationId: string }>());
export const resendOTPFailure = createAction('[Auth] resendOTP Failure', props<{ error: IAPIErrorResponse }>());

// master action (-- need to reLocate)
export const loadCountryRequest = createAction('[Auth] Load Country Data');
export const loadCountrySuccess = createAction('[Auth] Load Country Success', props<{ data: countryInfo[] }>());
export const loadCountryFailure = createAction('[Auth] Load Country Fail');

export const loadTimeZoneRequest = createAction('[Auth] Load Timezone Data');
export const loadTimeZoneSuccess = createAction('[Auth] Load Timezone Success', props<{ data: ITimeZoneInfo[] }>());
export const loadTimeZoneFailure = createAction('[Auth] Load Timezone Fail');

export const markasOnboardRequest = createAction('[Auth] Mark as Onboarded Request');
export const markasOnboardSuccess = createAction('[Auth] Mark as Onboarded Success');
export const markasOnboardFailure = createAction('[Auth] Mark as Onboarded Failed');

export const onboardUserRequest = createAction('[Auth] Onboarded User Request');
